<template>
  <!-- Skeleton table -->
  <transition name="el-fade-in" mode="out-in">
    <div v-if="loading" key="loading" class="fixed-wrapper">
      <table class="performance-reporting-table skeleton-table">
        <tr ref="header" class="performance-reporting-table__table-header">
          <th />
          <th v-for="id in (visibleAudiences.length || 2)" :key="id">
            <skeleton-box width="14px" height="30px" />
          </th>
          <th />
        </tr>
        <tr class="performance-reporting-table__main-heading">
          <td :colspan="(visibleAudiences.length || 2) + 2" class="performance-reporting-table__main-heading-title" />
        </tr>
        <template v-for="i in 3">
          <tr :key="`heading-${i}`" class="performance-reporting-table__event-label performance-reporting-table__event-label-skeleton">
            <td class="performance-reporting-table__metric-label performance-reporting-table__metric-label-skeleton">
              <skeleton-box width="175px" height="16px" />
            </td>
            <td :colspan="(visibleAudiences.length || 2) + 1" />
          </tr>
          <tr v-for="row in 4" :key="`content-${i}-${row}`" class="performance-reporting-table__metric-row">
            <td class="performance-reporting-table__metric-label performance-reporting-table__metric-label-skeleton">
              <skeleton-box :width="Math.floor(100 + (Math.random() * 60)) + 'px'" height="12px" />
            </td>
            <td v-for="td in (visibleAudiences.length || 2)" :key="td" class="performance-reporting-table__metric-cell performance-reporting-table__metric-cell-skeleton">
              <skeleton-box width="64px" height="12px" />
            </td>
            <td />
          </tr>
        </template>
      </table>

      <portal v-if="showStickyHeader" to="app-header-subcontent">
        <div class="fixed-wrapper">
          <table class="performance-reporting-table">
            <tbody
              id="table-sink"
              ref="sink" />
          </table>
        </div>
      </portal>
    </div>

    <!-- Empty state -->
    <div v-else-if="!hasMetrics" key="empty" class="fixed-wrapper fixed-wrapper--empty">
      <i class="el-icon-warning" />
      <app-text type="p">
        There is no data for this date range.
      </app-text>
      <app-text type="p">
        Please select another date range to compare data.
      </app-text>
    </div>

    <div v-else key="table" ref="scrollable" v-scrollable:xy class="fixed-wrapper">
      <!-- Main table -->
      <table class="performance-reporting-table" @click.stop="" @mousedown.stop="">
        <tbody>
          <tr ref="header" class="performance-reporting-table__table-header">
            <th />
            <th v-for="col in columns" :key="col.id">
              <span :title="col.name">{{ col.name }}</span>
            </th>
          </tr>
          <tr key="tr2" class="performance-reporting-table__main-heading">
            <td :colspan="columns.length + 1" class="performance-reporting-table__main-heading-title">
              {{ getAttributeLabel(selectedMetric) }}
            </td>
          </tr>
        </tbody>
        <tbody
          v-for="(value, key) in metrics"
          v-show="!hiddenMetrics[key]"
          :key="key"
          class="performance-reporting-table__event-group"
          :class="[collapsedMetrics[key] ? 'performance-reporting-table__event-group--collapsed' : '']">
          <tr :class="['performance-reporting-table__event-label']" @click="toggleDropdown(key)">
            <td :colspan="columns.length + 1">
              {{ getAttributeLabel(key) }}
              <i :class="[collapsedMetrics[key] ? 'el-icon-arrow-down' : 'el-icon-arrow-up']" />
            </td>
          </tr>
          <tr
            v-for="(metric, metricName) in value"
            v-show="!hiddenMetrics[key]"
            :key="metricName"
            :class="['performance-reporting-table__metric-row', collapsedMetrics[key] ? 'hidden' : '']">
            <td class="performance-reporting-table__metric-label">
              {{ getAttributeLabel(metricName) }}
            </td>
            <td v-for="(val, index) in metric" :key="index" class="performance-reporting-table__metric-cell" :class="{ 'performance-reporting-table__metric-cell--active': index < columns.length - 1 }">
              <app-gradient-cell
                v-if="index < columns.length - 1"
                :value="val"
                :max="localMax[metricName] || localMax[key][metricName]"
                :easing="isDemographics? (t,c,d) => (t/=d,c*t) : undefined">
                {{ formatCell(val, isDemographics ? 'demographics' : key, metricName) }}
              </app-gradient-cell>
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr class="performance-reporting-table__metric-label-spaceholder">
            <td>
            &nbsp;
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Overlay table -->
      <div class="table-overlay">
        <table class="performance-reporting-table">
          <tbody>
            <tr class="performance-reporting-table__table-header">
              <th />
              <th v-for="col in columns" :key="col.id">
                <span :title="col.name">{{ col.name }}</span>
              </th>
            </tr>
            <tr key="tr2" class="performance-reporting-table__main-heading">
              <td colspan="1" class="performance-reporting-table__main-heading-title">
                {{ selectedMetric }}
              </td>
            </tr>
          </tbody>
          <tbody
            v-for="(value, key) in metrics"
            v-show="!hiddenMetrics[key]"
            :key="key"
            class="performance-reporting-table__event-group"
            :class="[collapsedMetrics[key] ? 'performance-reporting-table__event-group--collapsed' : '']">
            <tr class="performance-reporting-table__event-label" @click="toggleDropdown(key)">
              <td colspan="1">
                {{ getAttributeLabel(key) }}
                <i :class="[collapsedMetrics[key] ? 'el-icon-arrow-down' : 'el-icon-arrow-up']" />
              </td>
            </tr>
            <tr
              v-for="(metric, metricName) in value"
              v-show="!hiddenMetrics[key]"
              :key="metricName"
              :class="['performance-reporting-table__metric-row', collapsedMetrics[key] ? 'hidden' : '']">
              <td class="performance-reporting-table__metric-label">
                {{ getAttributeLabel(metricName) }}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="performance-reporting-table__metric-label-spaceholder">
              <td>
              &nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <portal v-if="showStickyHeader" to="app-header-subcontent">
        <div class="fixed-wrapper">
          <table class="performance-reporting-table">
            <tbody
              id="table-sink"
              ref="sink" />
          </table>
          <div class="overlay" />
        </div>
        <div class="header-overlay">
          <div class="overlay" />
        </div>
      </portal>
    </div>
  </transition>
</template>

<script>
import _get from 'lodash/get';
import { TABLE_DATA_LABELS, ATTRIBUTE_FORMATTING } from '@/constants/performance';
import AppGradientCell from '@/components/AppGradientCell';
import AppText from '@/components/AppText';
import money from '@/services/money';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import Breakpoints from '@/mixins/Breakpoints';

export default {
  name: 'PerformanceReportingTable',
  components: { AppGradientCell, AppText, SkeletonBox },
  mixins: [Breakpoints([450, 768, 992])],
  data() {
    return {
      collapsedMetrics: {},
      showStickyHeader: false,
      appHeaderHeight: 60,
      scrollables: []
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoadingAudienceMetrics;
    },
    crowdId() {
      return this.$route.params.crowdId;
    },
    crowd() {
      return this.$store.getters.getCrowdById(this.crowdId);
    },
    visibleAudiences() {
      return (this.crowd || { audiences: [] }).audiences.filter((audience) => audience.visible).map(a => a.id);
    },
    selectedMetric() {
      return this.$store.getters.selectedMetric;
    },
    isDemographics() {
      return this.selectedMetric === 'demographics';
    },
    hiddenMetrics() {
      const hiddenMetrics = {};
      Object.entries(this.localMax).forEach(([section, data]) => {
        if (Object.values(data).every((val) => !val)) hiddenMetrics[section] = true;
      });

      return hiddenMetrics;
    },
    crowdMetrics() {
      return this.$store.getters.crowdMetrics;
    },
    columns() {
      return _get(this.crowdMetrics, 'columns', []);
    },
    localMax() {
      return _get(this.crowdMetrics, 'localMax', {});
    },
    globalMax() {
      return _get(this.crowdMetrics, 'globalMax', {});
    },
    metrics() {
      return _get(this.crowdMetrics, 'metrics', {});
    },
    hasMetrics() {
      return Object.values(this.globalMax).some((val) => !!val);
    }
  },
  watch: {
    showStickyHeader: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.stickyHeader());
      }
    },
    loading() {
      this.$nextTick(() => this.stickyHeader());
    },
    hasMetrics() {
      this.$nextTick(() => this.stickyHeader());
    },
    windowBreakpoint() {
      this.handleScroll();
      this.stickyHeader();
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, this.$supportsPassive && { passive: true });
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, this.$supportsPassive && { passive: true });
    this.$nextTick(this.handleScroll);
  },
  methods: {
    getAttributeLabel(key) {
      return TABLE_DATA_LABELS[key] || key;
    },
    toggleDropdown(key) {
      if (this.collapsedMetrics[key]) {
        this.$delete(this.collapsedMetrics, key);
      } else {
        this.$set(this.collapsedMetrics, key, true);
      }
    },
    formatCell(value, group, column) {
      if (!value || value === '-') return '-';
      return money.apply(null, [value].concat(ATTRIBUTE_FORMATTING[column] || ATTRIBUTE_FORMATTING[group] || [true, '']));
    },
    handleScroll() {
      try {
        this.showStickyHeader = this.$refs.header.getBoundingClientRect().top <= this.appHeaderHeight;
      } catch (e) {
        this.showStickyHeader = false;
      }
    },
    // eslint-disable-next-line complexity,sonarjs/cognitive-complexity
    stickyHeader() {
      if (!this.$refs || !this.$refs.header || !this.showStickyHeader) return;

      const ref = this.$refs.sink;
      const elm = this.$refs.header;

      if (ref) {
        // Remove existing children
        while (ref.firstChild) {
          ref.removeChild(ref.firstChild);
        }

        // Append header
        if (elm) {
          const clone = elm.cloneNode(true);
          ref.appendChild(clone);

          ;
          // Keep scroll positions in sync
          const scrollables = this.scrollables = Array.from(document.querySelectorAll('.fixed-wrapper'));
          scrollables[0].scrollLeft = scrollables[1].scrollLeft;
          scrollables[0].addEventListener('scroll', () => { scrollables[1].scrollLeft = scrollables[0].scrollLeft; }, this.$supportsPassive && { passive: true });
          scrollables[1].removeEventListener('scroll', this.copyScroll, this.$supportsPassive && { passive: true });
          scrollables[1].addEventListener('scroll', this.copyScroll, this.$supportsPassive && { passive: true });
        }
      }
    },
    copyScroll() {
      if (!this.scrollables.length) return;
      this.scrollables[0].scrollLeft = this.scrollables[1].scrollLeft;
    }
  }
};
</script>

<style lang="scss">
.fixed-wrapper {
  width: 100%;
  overflow-x: auto;
  background: $--clb-color-primary__white;
  border: 1px solid $--clb-color-grey__grey-lighter;
  border-radius: $--clb-border-radius $--clb-border-radius 0 0;
  margin-bottom: $--clb-layout-3;

  &--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: initial;
    height: 400px;
    text-align: center;
    padding: $--clb-layout-3;

    i {
      display: block;
      color: $--clb-color-primary;
      font-size: 76px;
      margin-bottom: $--clb-layout-2;
    }

    .app-text {
      line-height: 26px;
    }
  }
}

.performance-reporting-table {
  background: transparent;
  user-select: none;
  border-spacing: 0;
  width: 100%;

  tr {
    height: auto;
  }

  &__table-header {
    th {
      border-right: $--clb-border-complete-light;
      padding: $--clb-space-4 0;
      min-width: 4rem;
      vertical-align: bottom;

      &:last-child {
        border-right: 0;
        width: 100%;
      }

      span,
      svg {
        display: block;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        margin: 0 auto;
        transform: rotate(180deg);
        height: 16ch;
        max-height: 180px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__main-heading {
    @apply tw-bg-jb-indigo-darker tw-text-lg;

    &-title {
      color: $--clb-color-primary__white;
      font-weight: bold;
      padding: 0 $--clb-space-4;
      vertical-align: middle;
      height: 50px;
      text-transform: capitalize;
    }
  }

  &__event-label {
    background: $--clb-color-secondary__light;
    overflow-x: hidden;

    td {
      @extend .performance-reporting-table__main-heading-title;

      padding: $--clb-space-4;
      color: $--clb-color-accent__dark;
    }

    &-skeleton {
      margin: 0;
    }
  }

  &__event-group {
    transition: 200ms ease-in-out;

    &--collapsed > .performance-reporting-table__event-label > td {
      color: $--clb-color-grey__grey-dark;
    }
  }

  td.performance-reporting-table__metric-label {
    background: $--clb-color-primary__white;
    height: 50px;

    &-skeleton svg {
      margin: 0;
    }
  }

  tr.performance-reporting-table__event-label > td {
    background-color: $--clb-color-grey__white-ter;
    border-bottom: $--clb-border-complete-dark;
  }

  &__metric {
    &-row {
      position: relative;
      height: 50px;
      transition: 200ms ease-in-out;

      td {
        transition: 200ms ease-in-out, font-size 100ms ease-in-out 100ms;
        border-bottom: $--clb-border-complete-light;

        > * {
          transition: color 200ms ease-in-out;
        }
      }

      &.hidden {
        height: 0 !important;

        td {
          transition: 200ms ease-in-out, font-size 0ms;
          height: 0;
          border: 0 solid $--clb-border-color-lighter !important;
          font-size: 0 !important;
          line-height: 0;

          > * {
            color: transparent !important;
          }
        }
      }
    }

    &-label {
      color: $--clb-body-font;
      padding-left: $--clb-layout-3;
      text-align: left;
      display: flex;
      align-items: center;
      min-width: 210px;

      @include xs-up {
        min-width: 298px;
      }

      span {
        word-break: break-word;
      }

      &-spaceholder {
        td {
          height: $--clb-space-4;
        }
      }
    }

    &-cell {
      width: 4rem;
      padding: 0 0 0 1px;
      overflow: hidden;
      height: 50px;
      vertical-align: middle !important;

      &--active {
        border-bottom: 1px solid $--clb-color-primary__white !important;
      }

      &-skeleton {
        background: $--clb-color-grey__white-est;
      }

      .skeleton-box {
        width: 26px;
        margin: 0 auto;
      }

      &:last-child {
        width: 100%;
      }
    }
  }

  .el-icon-arrow-up,
  .el-icon-arrow-down {
    padding-left: $--clb-space-2;
    vertical-align: middle;
    font-size: $--clb-font-size-lg;
    font-weight: $--clb-font-weight__bolder;
    position: relative;
    top: -1px;
  }

  .el-icon-arrow-up {
    color: $--clb-color-grey__grey-dark;
  }
}

.table-overlay {
  z-index: 1;
  top: 0;
  left: 1px;
  position: absolute;
  overflow: hidden;
  width: 210px;
  display: block;
  border: 1px solid $--clb-color-grey__grey-lighter;
  border-right: 0;
  border-left: 0;
  border-radius: $--clb-border-radius;

  th:nth-child(1) {
    background: linear-gradient(to right, $--clb-color-primary__white, $--clb-color-primary__white, rgba(white, 0.75));
  }

  @include xs-up {
    width: 298px;
  }
}

.app-header__portal .fixed-wrapper,
.app-header__portal .header-overlay {
  position: relative;
  width: 100%;
  max-width: calc(100% - #{$--clb-space-4 * 2});
  margin: 0 auto;
  padding: 0;
  border: none;
  border-radius: 0;
  border-left: 1px solid $--clb-color-grey__grey-lighter;
  border-right: 1px solid $--clb-color-grey__grey-lighter;
  border-bottom: 1px solid $--clb-color-grey__grey-lighter;
  scrollbar-width: none;

  @media (min-width: $--sm) {
    max-width: calc(100% - #{$--clb-layout-4 * 2});

    ~ .header-overlay {
      left: $--clb-layout-4 + 1;
    }
  }

  @media (min-width: $--md) {
    max-width: calc(100% - #{($--clb-layout-2 + $--clb-layout-3) * 2});

    ~ .header-overlay {
      left: $--clb-layout-2 + $--clb-layout-3 + 1;
    }
  }

  @media (min-width: $--lg) {
    max-width: calc(100% - #{($--clb-layout-4 + $--clb-layout-3) * 2});

    ~ .header-overlay {
      left: $--clb-layout-4 + $--clb-layout-3 + 1;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .performance-reporting-table {
    border-top: none;
    border-bottom: none;
    width: 100%;
    margin-left: 0 !important;

    th:first-child {
      min-width: 210px;
    }

    @media (min-width: $--xs) {
      th:first-child {
        min-width: 298px;
      }
    }
  }
}

.app-header__portal .fixed-wrapper .overlay {
  display: none;

  @media (min-width: #{$--clb-app-content-width + $--clb-app-left-sidebar-width}) {
    display: block;
  }
}

.app-header__portal .header-overlay {
  position: absolute;
  top: 61px;
  left: 1px;
  height: calc(100% - 61px);
  width: #{210px - 1px};
  margin-right: 0 !important;
  border: none;

  @include xs-up {
    width: #{298px - 2px};
  }

  @media (min-width: $--md) {
    top: 0;
    height: calc(100% - 1px);
  }

  @media (min-width: #{$--clb-app-content-width + $--clb-app-left-sidebar-width}) {
    display: none;
  }
}

.app-header__portal .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: #{210px - 2px};
  background: linear-gradient(to right, $--clb-color-primary__white, $--clb-color-primary__white, rgba(white, 0.75));

  @media (min-width: $--xs) {
    width: #{298px - 1px};
  }
}
</style>
