<template>
  <div class="performance-audience-selection">
    <slot />
    <div class="performance-audience-selection__heading">
      <app-text type="h3" weight="bold" color="headings" class="performance-audience-selection__heading--title">
        {{ crowd.name }}
        <el-popover
          v-if="leftSidebarVisible"
          :value="showPopover"
          placement="bottom"
          width="280"
          :offset="-90"
          :trigger="!popoverDismissed ? 'manual' : 'hover'"
          :title="!popoverDismissed ? 'Step 3/4' : ''"
          :content="tooltipInfoText"
          popper-class="dark performance-audience-selection__popover">
          <i
            slot="reference"
            class="el-icon-info"
            @mouseover="popover = true"
            @click="popover = true" />
          {{ tooltipInfoText }}
          <app-button v-if="!popoverDismissed" size="micro" state="primary" class="performance-audience-selection__popover-button" @click="dismissPopover">
            Got It!
          </app-button>
        </el-popover>
      </app-text>

      <div class="performance-audience-selection__heading--subtitle">
        <app-text type="p" color="primary" weight="semi-bold" :title="crowd.constraint_id" class="performance-audience-selection__heading--subtitle-id">
          {{ crowd.constraint_id }}
        </app-text>
        <app-text type="p" size="default" weight="bold" :title="crowd.constraint_name" class="performance-audience-selection__heading--subtitle-name">
          {{ crowd.constraint_name }}
        </app-text>
      </div>

      <div class="performance-audience-selection__heading--toggle">
        <el-switch
          v-model="selectedMetric"
          :disabled="!audiences.length"
          active-color="#5072ff"
          inactive-color="#5072ff"
          active-text="Demographics"
          active-value="demographics"
          inactive-text="Performance"
          inactive-value="performance" />
      </div>

      <div class="performance-audience-selection__heading--picker">
        <date-picker-filter :value="dateFilter" @input="handleDateFilter" />
      </div>
    </div>

    <div class="performance-audience-selection__controls">
      <div class="performance-audience-selection__controls--add-item">
        <i
          v-keyboard-action
          v-tippy="{ placement:'right', content: 'Add Audience', enabled: true, showOnInit: false, arrow: true, followCursor: false }"
          class="el-icon-circle-plus"
          tabindex="0"
          @click="$router.push({ name: 'insights-audience-create', params: { crowdId } })" />
      </div>
      <div class="performance-audience-selection__controls--search">
        <i class="el-icon-search" />
        <input v-model="search" placeholder="Search Audiences">
      </div>
    </div>

    <el-scrollbar ref="scrollbar" class="performance-audience-selection__cascade" :native="$supportsTouch">
      <transition-group
        v-if="audiences.length"
        name="slide-up"
        tag="div"
        class="performance-audience-selection__audience-list">
        <div
          v-for="audience in computedAudiences"
          :key="`audience-row-${audience.id}`"
          class="performance-audience-selection__audience-row"
          :class="{
            ['performance-audience-selection__audience-row--expanded']: expandedAudiences[audience.id],
            ['performance-audience-selection__audience-row--hidden'] : !audience.visible
          }"
          @click="handleDropdownClick(audience.id)">
          <div class="performance-audience-selection__audience-row-wrapper">
            <div class="performance-audience-selection__audience-info">
              <app-text class="performance-audience-selection__audience-name" :title="audience.name" type="p" weight="medium">
                {{ audience.name }}
                <el-popover
                  placement="right"
                  title="Benchmark Performance"
                  width="300"
                  trigger="hover"
                  popper-class="dark">
                  This audience consists of full set of users within your crowd. Use it as an aggregate performance benchmark. If an audience outperforms this baseline than it is better than average, and vice-versa.
                  <i v-if="audience.id === '0'" slot="reference" class="el-icon-info tw-ml-space-1 tw-text-jb-grey" title="" />
                </el-popover>
              </app-text>
            </div>
            <div class="performance-audience-selection__audience-actions">
              <el-checkbox
                v-if="audience.id !== '0'"
                :id="`active-audience-${audience.id}`"
                :key="audience.id"
                v-keyboard-action
                :checked="audience.visible"
                :label="audience"
                tabindex="0"
                @click.native.stop
                @change="handleViewAudience(audience)">
                <i class="el-icon-view" />
              </el-checkbox>
              <el-dropdown v-if="audience.id !== '0'" v-keyboard-action trigger="click" @click.native.stop>
                <span class="el-dropdown-link">
                  <i :class="audienceBeingDeleted === audience.id ? 'el-icon-loading' : 'el-icon-more'" />
                </span>
                <el-dropdown-menu slot="dropdown" class="performance-audience-dd-menu">
                  <el-dropdown-item
                    class="audience-dropdown"
                    :icon="audienceBeingDeleted === audience.id ? 'el-icon-loading' : 'el-icon-delete'"
                    :disabled="audienceBeingDeleted === audience.id"
                    @click.native="handleDeletedAudience(audience)">
                    Delete
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <i v-if="Object.keys(audience.attributes).length" v-keyboard-action tabindex="0" class="el-icon-arrow-down" />
            </div>
          </div>

          <el-collapse-transition>
            <ul v-if="expandedAudiences[audience.id]" class="performance-audience-selection__audience-attribute-list">
              <li v-for="attribute in getAttributesArray(audience.attributes)" :key="`audience-row-${audience.id}-attribute-${attribute.type}-${attribute.id}`" class="performance-audience-selection__audience-attribute-row" :title="getAttributeName(attribute)">
                <app-text class="performance-audience-selection__audience-attribute-info" size="xs">
                  <app-text class="performance-audience-selection__audience-attribute-type" color="grey-dark" weight="medium">
                    {{ attributeLabels[attribute.type] || attribute.type }}:
                  </app-text>
                  <app-text v-if="showAttributeId(attribute)" class="performance-audience-selection__audience-attribute-id" color="primary">
                    {{ getAttributeId(attribute) }}
                  </app-text>
                  <app-text class="performance-audience-selection__audience-attribute-name" :title="getAttributeName(attribute)" color="grey-dark">
                    {{ getAttributeName(attribute) }}
                  </app-text>
                </app-text>
              </li>
            </ul>
          </el-collapse-transition>
        </div>
        <div key="audience-row-no-search-results" class="performance-audience-selection__audience-row performance-audience-selection__audience-row--empty" :style="{ display: !computedAudiences.length? 'flex': 'none' }">
          <div class="performance-audience-selection__audience-row-wrapper">
            <app-text type="span" size="xs" color="grey-dark">
              No audience found matching "{{ search }}"
            </app-text>
          </div>
        </div>
      </transition-group>

      <div v-else-if="Object.keys(crowd)" class="performance-audience-selection__audience-list performance-audience-selection__audience-list--empty">
        <app-text type="p" color="info">
          Tap <i
            v-keyboard-action
            v-tippy="{ placement: 'top', content: 'Add Audience', enabled: true, showOnInit: false, arrow: true, followCursor: false }"
            class="el-icon-circle-plus"
            @click="$router.push({ name: 'insights-audience-create', params: { crowdId } })" /> or use the button below to create your first audience
        </app-text>
        <app-button @click="$router.push({ name: 'insights-audience-create', params: { crowdId } })">
          Create Audience
        </app-button>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { Scrollbar } from 'element-ui';
import AppButton from '@/components/AppButton';
import AppText from '@/components/AppText';
import _orderBy from 'lodash/orderBy';
import DatePickerFilter from '@/components/filters/DatePickerFilter';
import PerformanceAnalyticsMixin from '@/mixins/PerformanceAnalyticsMixin';
import { MINIMUM_COLUMN_COUNT_ON_PERFORMANCE_REPORT_TABLE, ATTRIBUTE_LABELS, TOOLTIP_AUDIENCE_HELPER_TEXT } from '@/constants/performance';

export default {
  name: 'PerformanceAudienceSelection',
  components: { 'el-scrollbar': Scrollbar, AppButton, AppText, DatePickerFilter },
  mixins: [PerformanceAnalyticsMixin],
  data() {
    return {
      expandedAudiences: {},
      audienceBeingDeleted: null,
      tooltipInfoText: TOOLTIP_AUDIENCE_HELPER_TEXT,
      attributeLabels: ATTRIBUTE_LABELS
    };
  },
  computed: {
    computedAudiences() {
      return _orderBy(this.audiences, ['visible', 'updated'], ['desc', 'asc']).filter((audience) => {
        return !this.search || audience.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    selectedMetric: {
      get() { return this.$store.getters.selectedMetric; },
      set(val) { this.$store.dispatch('setCrowdSelectedMetric', val); }
    }
  },
  methods: {
    getAttributesArray(attributes) {
      return Object.values(attributes).reduce((acc, curr) => [...acc, ...curr], []);
    },
    handleDropdownClick(audienceId, $event) {
      this.$set(this.expandedAudiences, audienceId, !(this.expandedAudiences[audienceId] || false));
    },
    async handleDeletedAudience(audience) {
      try {
        await this.$api.crowds.deleteAudience({
          crowdId: this.crowdId,
          audienceId: audience.id,
          storeAction: 'deleteAudience'
        });
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      }
    },
    async handleViewAudience(audience) {
      try {
        document.activeElement.blur();
        if (!audience.visible && this.visibleAudiences.length >= MINIMUM_COLUMN_COUNT_ON_PERFORMANCE_REPORT_TABLE) {
          return this.$message({ message: 'You must hide an audience before viewing more', type: 'error' });
        }

        const visibleAudiences = this.visibleAudiences.map(a => a.id);
        const existingIndex = visibleAudiences.indexOf(audience.id);
        existingIndex !== -1 ? visibleAudiences.splice(existingIndex, 1) : visibleAudiences.push(audience.id);

        audience.visible && this.$delete(this.expandedAudiences, audience.id);
        await this.$api.crowds.setCrowdView({
          crowdId: this.crowdId,
          params: { view: visibleAudiences },
          storeAction: 'setCrowdView'
        });
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      }
    }
  }
};
</script>

<style lang="scss">

.performance-audience-selection {
  &__heading {
    padding: 0 $--clb-space-4;

    &--title {
      margin: 0;
      margin-bottom: $--clb-space-2;
      font-size: $--clb-h3__font-size;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      span {
        cursor: pointer;
        display: inline;
        font-size: $--clb-font-size-base;
        color: $--clb-body-font;
      }
    }

    &--subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-transform: capitalize;

      &-id {
        white-space: nowrap;
      }

      &-name {
        margin-left: $--clb-space-2;
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &--picker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: $--clb-space-3;
      margin-bottom: $--clb-space-4;

      .date-picker-filter__calendar {
        margin-top: $--clb-space-2;
      }

      .el-input__inner {
        min-height: 40px !important;

        .el-range-separator {
          height: 25px;
        }
      }
    }

    &--toggle {
      display: block;
      margin-top: $--clb-space-3;

      .el-switch__label {
        * {
          font-size: $--clb-font-size-xs;
          font-weight: $--clb-font-weight__bold;
          transition: all 0.5s ease;
          color: $--clb-color-grey__grey;
        }

        &.is-active * {
          color: $--clb-color-primary;
          transition: all 0.5s ease;
        }
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $--clb-space-4;
    border-top: 1px solid $--clb-color-grey__grey-lighter;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    z-index: 1;
    box-shadow: 0 30px 50px 0 rgba(96, 96, 99, 0.05);

    &--add-item {
      min-width: $--clb-layout-3;
      display: flex;
      align-items: center;

      .el-icon-circle-plus {
        color: $--clb-color-primary;
        cursor: pointer;
      }
    }

    &--search {
      display: flex;
      align-items: center;
      padding-left: $--clb-space-4;
      justify-content: flex-end;
      border-left: 1px solid $--clb-color-grey__grey-lighter;

      .el-icon-search {
        font-size: $--clb-font-size-sm;
      }

      input {
        padding-left: $--clb-space-5;
        border: none;
        font-size: $--clb-font-size-sm;

        &::placeholder {
          text-transform: capitalize;
          color: $--color-text-jb-placeholder;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__cascade {
    position: relative;
    width: 100%;
    flex: 1;
  }

  &__audience-row-wrapper {
    padding: $--clb-space-4;
    text-transform: capitalize;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: background-color 200ms ease-in-out;

    .el-icon-arrow-down {
      margin-left: $--clb-space-2;
      color: $--clb-color-grey__grey-light;
      font-weight: $--clb-font-weight__semi-bold;
      transform-origin: center;
      transition: transform 200ms ease-in-out, color 200ms ease-in-out;

      &:hover {
        color: $--clb-color-grey__grey;
      }
    }
  }

  &__audience-info {
    display: flex;
    align-items: flex-start;
    font-weight: $--clb-font-weight__semi-bold;
    max-width: 149px;
  }

  &__audience-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
  }

  &__audience-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-icon-view,
    .el-icon-more {
      cursor: pointer;
      color: $--clb-color-grey__grey-light;
      transition: 200ms ease-in-out;

      &:hover {
        color: $--clb-color-grey__grey;
      }
    }

    .el-icon-view {
      font-weight: $--clb-font-weight__bold;
      color: $--clb-color-grey__grey-dark;
    }

    .el-checkbox {
      .el-checkbox__input {
        visibility: hidden;
        position: absolute;
        top: -9999px;
        left: -9999px;
        z-index: -1;
      }

      .el-checkbox__label {
        padding: 0 $--clb-space-2;
      }
    }

    .el-icon-more {
      transform: rotate(90deg);
    }

    ul.el-dropdown-menu.el-popper {
      cursor: pointer;
      padding: $--clb-space-2 $--clb-space-3 $--clb-space-3 $--clb-space-3;
      transition: background-color 0.2s ease-in-out;

      &__item {
        :hover {
          background: $--clb-color-secondary__light;
        }
      }
    }
  }

  &__audience-attribute-list {
    position: relative;
    z-index: 1;

    li:last-child {
      margin-bottom: $--clb-space-4;
    }
  }

  &__audience-attribute-row {
    padding: $--clb-space-1 $--clb-space-5;
    text-transform: capitalize;
    width: 100%;
    display: flex;
    font-size: $--clb-font-size-sm;
    color: $--clb-body-font;
    user-select: none;
  }

  &__audience-attribute {
    &-info {
      width: 100%;
      display: flex;
      white-space: nowrap;

      > .app-text {
        margin-right: $--clb-space-1;
      }

      &::before {
        content: '•';
        margin-right: $--clb-space-2;
        font-weight: $--clb-font-weight__bolder;
        color: $--clb-color-grey__grey-light;
      }
    }

    &-name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__audience-row {
    cursor: pointer;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    transition: all 200ms ease-in-out;

    @apply tw-bg-white sm:hover:tw-bg-jb-grey-hover;

    &--expanded {
      .el-icon-arrow-down {
        color: $--clb-color-primary;
        transform: rotate(180deg);
      }
    }

    &--hidden {
      @apply tw-bg-grey-white-ter hover:tw-bg-grey-white-ter sm:hover:tw-bg-grey-white-ter;

      .performance-audience-selection__audience-name {
        color: $--clb-color-grey__grey;
      }

      .performance-audience-selection__audience-attribute-row {
        opacity: 0.5;
      }

      .performance-audience-selection__audience-actions {
        .el-icon-view {
          color: $--clb-color-grey__grey-light;

          &:hover {
            color: $--clb-color-grey__grey-darker;
          }
        }
      }
    }
  }

  &__audience-list {
    overflow: hidden;

    &--empty {
      text-align: center;
      padding: $--clb-layout-5 $--clb-space-3;

      p {
        color: $--clb-color-info;
      }

      .el-icon-circle-plus {
        color: $--clb-color-primary;
        cursor: pointer;
      }

      .app-button {
        margin: 1em auto;
      }
    }
  }

  .el-scrollbar {
    &__wrap {
      overflow-x: hidden;
      margin-bottom: 0 !important;
      overscroll-behavior: contain;
    }

    &__bar.is-horizontal {
      display: none;
    }
  }

  &__popover {
    &-button {
      margin: $--clb-space-4 0 0 auto;
    }
  }
}

.el-dropdown-menu__item.audience-dropdown {
  padding: $--clb-space-2 $--clb-space-3;
  line-height: 1.5;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}

</style>
