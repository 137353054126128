<template>
  <div class="performance-analytics-report">
    <div class="performance-analytics-report__title">
      <div class="performance-analytics-report__title--wrap">
        <h3><span class="hidden-xs">Crowd</span> Insights</h3>
        <transition name="el-fade-in" mode="out-in">
          <h4 v-if="crowd" :title="crowd.name">
            {{ crowd.name }}
          </h4>
          <h4 v-else />
        </transition>
      </div>
      <transition name="el-fade-in" mode="out-in">
        <app-button v-if="crowd" state="indigo" @click="handleHeaderButtonClick">
          <i class="el-icon-plus" /> Add Audience
        </app-button>
      </transition>
    </div>
    <div class="performance-analytics-report__content">
      <transition name="el-fade-in" mode="out-in">
        <performance-reporting-table v-if="crowdId && (visibleAudiences.length || isLoadingAudienceMetrics || isLoadingCrowds)" key="table" keepalive />
        <performance-reporting-overlay v-else key="overlay" keepalive />
      </transition>
      <transition name="el-fade-in" mode="out-in">
        <performance-reporting-instructions v-if="!crowdId" />
        <div v-else />
      </transition>
    </div>
    <portal to="app-right-sidebar-extension">
      <div key="app-right-sidebar-extension--performance" class="performance-analytics-report__sidebar tw-bg-white">
        <transition :name="transition" mode="out-in">
          <Component :is="sidebarComponent" :key="sidebarComponent" :crowd-id="crowdId">
            <span
              v-if="backText"
              :key="$route.name"
              v-keyboard-action
              class="performance-analytics-report__sidebar-header--location"
              tabindex="0"
              @click="handleGoBack">
              <i class="el-icon-arrow-left" /> {{ backText }}
            </span>
          </Component>
        </transition>
      </div>
    </portal>

    <portal to="app-right-sidebar-extension-handler">
      <app-sidebar-handle key="app-right-sidebar-extension-handler--performance" slot-scope="props" @click="props.click">
        {{ currentSidebarText }}
      </app-sidebar-handle>
    </portal>
  </div>
</template>

<script>
import _omitBy from 'lodash/omitBy';
import _isEqual from 'lodash/isEqual';
import Breakpoints from '@/mixins/Breakpoints';
import isNil from 'lodash/isNil';
import AppButton from '@/components/AppButton';
import AppSidebarHandle from '@/components/AppSidebarHandle.vue';
import PerformanceAttributeSelection from '@/components/performance/PerformanceAttributeSelection';
import PerformanceAudienceSelection from '@/components/performance/PerformanceAudienceSelection';
import PerformanceCrowdAttributeSelection from '@/components/performance/PerformanceCrowdAttributeSelection';
import PerformanceCrowdConstraintSelection from '@/components/performance/PerformanceCrowdConstraintSelection';
import PerformanceCrowdSelection from '@/components/performance/PerformanceCrowdSelection';
import PerformanceReportingInstructions from '@/components/performance/PerformanceReportingInstructions';
import PerformanceReportingOverlay from '@/components/performance/PerformanceReportingOverlay';
import PerformanceReportingTable from '@/components/performance/PerformanceReportingTable';
import routes from '@/router/routes';

export default {
  name: 'PerformanceAnalyticsReport',
  components: {
    AppButton,
    AppSidebarHandle,
    PerformanceReportingTable,
    PerformanceCrowdSelection,
    PerformanceCrowdConstraintSelection,
    PerformanceCrowdAttributeSelection,
    PerformanceAudienceSelection,
    PerformanceAttributeSelection,
    PerformanceReportingInstructions,
    PerformanceReportingOverlay
  },
  mixins: [Breakpoints([992])],
  beforeRouteLeave(to, from, next) {
    return next(
      to.path.startsWith('/insights') && JSON.stringify(to.query) !== JSON.stringify(this.query)
        ? { ...to, query: this.query }
        : undefined
    );
  },
  data() {
    return {
      transition: 'slide-left'
    };
  },
  computed: {
    crowdId() {
      return this.$route.params.crowdId;
    },
    crowd() {
      return this.$store.getters.getCrowdById(this.crowdId);
    },
    visibleAudiences() {
      return (this.crowd || { audiences: [] }).audiences.filter((audience) => audience.visible).map(a => a.id);
    },
    isLoadingCrowds() {
      return this.$store.getters.isLoadingCrowds;
    },
    isLoadingAudienceMetrics() {
      return this.$store.getters.isLoadingAudienceMetrics;
    },
    dateFilter() {
      return this.$store.getters.crowdDateFilter;
    },
    isMobile() {
      return this.windowBreakpoint <= 0;
    },
    hasAccessToCrowd() {
      return !this.isLoadingCrowds && (!this.crowdId || this.crowd);
    },
    sidebarComponent() {
      return {
        insights: 'performance-crowd-selection',
        'insights-crowd-create': 'performance-crowd-constraint-selection',
        'insights-crowd-create-attributes': 'performance-crowd-attribute-selection',
        'insights-crowd': 'performance-audience-selection',
        'insights-audience-create': 'performance-attribute-selection'
      }[!this.hasAccessToCrowd ? 'insights' : this.$route.name];
    },
    currentSidebarText() {
      return {
        insights: 'Crowds',
        'insights-crowd-create': 'Build Crowd',
        'insights-crowd-create-attributes': 'Build Crowd',
        'insights-crowd': 'Audiences',
        'insights-audience-create': 'Build Audience'
      }[!this.hasAccessToCrowd ? 'insights' : this.$route.name];
    },
    backText() {
      return {
        'insights-crowd-create': 'All Crowds',
        'insights-crowd-create-attributes': 'Crowd Constraint',
        'insights-crowd': 'All Crowds',
        'insights-audience-create': 'Crowd'
      }[this.$route.name];
    },
    query() {
      return this.crowd && this.hasAccessToCrowd ? _omitBy({
        ...this.dateFilter,
        audiences: this.visibleAudiences.length ? this.visibleAudiences.join(',') : undefined,
        view: this.$store.getters.selectedMetric
      }, isNil) : {};
    }
  },
  watch: {
    $route(to, from) {
      const analyticsRoutes = routes.find((route) => route.path === '/insights').children;
      const nextIndex = analyticsRoutes.findIndex((route) => route.name === to.name);
      const prevIndex = analyticsRoutes.findIndex((route) => route.name === from.name);
      if (nextIndex !== -1 && prevIndex !== -1 && nextIndex !== prevIndex) this.transition = nextIndex < prevIndex ? 'slide-right' : 'slide-left';
    },
    query(newVal, oldVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.$router.replace({ ...this.$route, query: this.query });
        if (['insights-crowd', 'insights-audience-create'].includes(this.$route.name)) this.getCrowdMetrics();
      }
    },
    isLoadingCrowds(newVal) {
      // User no longer has access to this crowd
      if (!newVal && !this.hasAccessToCrowd) this.$router.replace({ name: 'insights' });
    }
  },
  async created() {
    this.initialAudiences = ['0'].concat(this.$route.query.audiences ? this.$route.query.audiences.split(',') : []);
    this.getCrowdMetrics(this.initialAudiences);

    this.$store.dispatch('updateStoreFromRoute', { to: this.$route });
    await this.$api.crowds.all({ storeAction: 'fetchCrowds' });

    if (this.crowd && this.initialAudiences && !_isEqual(this.initialAudiences, this.visibleAudiences)) {
      this.$api.crowds.setCrowdView({ crowdId: this.crowdId, params: { view: this.initialAudiences }, storeAction: 'setCrowdView' });
    }
  },
  methods: {
    async getCrowdMetrics(audiences = this.visibleAudiences) {
      try {
        if (!this.crowdId || !audiences.length) return;
        await this.$api.crowds.crowdMetrics({
          view: this.$store.getters.selectedMetric,
          crowdId: this.crowdId,
          params: { ...this.dateFilter, audienceIds: audiences.join(',') },
          storeAction: 'fetchAudienceMetrics'
        });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      }
    },
    handleHeaderButtonClick() {
      this.$store.dispatch('setRightDrawerVisibility', true);
      this.$router.push({ name: 'insights-audience-create', params: this.$route.params });
    },
    handleGoBack() {
      document.activeElement.blur();
      switch (this.$route.name) {
        case 'insights-crowd-create':
          return this.$router.replace({ name: 'insights' });
        case 'insights-crowd-create-attributes':
          return this.$router.replace({ name: 'insights-crowd-create' });
        case 'insights-crowd':
          return this.$router.replace({ name: 'insights' });
        case 'insights-audience-create':
          return this.$router.replace({ name: 'insights-crowd', params: this.$route.params });
      }
    }
  }
};
</script>

<style lang="scss">
.performance-analytics-report {
  @include page-layout;

  &__title {
    display: flex;
    justify-content: space-between;
    margin: 0 auto $--clb-layout-3;

    &--wrap {
      flex: 7 1 auto;
      padding-right: $--clb-space-4;

      h3 {
        margin-top: 0;
        margin-bottom: $--clb-space-2;
      }

      h4 {
        color: $--clb-color-grey__grey-darker;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .app-button {
      display: flex;
      align-self: center;
      width: 182px;
      white-space: nowrap;

      i {
        margin-right: $--clb-space-2;
      }
    }
  }

  &__content {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    position: relative;

    &-close {
      position: absolute;
      top: 8px;
      right: 13px;
    }

    @include lg-up {
      align-content: space-between;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
    }
  }

  .performance-analytics-report__title h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .performance-reporting-instructions {
    padding: $--clb-layout-1;

    @include sm-up {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $--clb-layout-3;
    }

    @include lg-up {
      display: block;
      flex-basis: 250px;
      flex-grow: 1;
      margin-left: $--clb-layout-3;
    }

    p {
      hyphens: none;
      line-height: $--clb-base__line-height;
    }
  }

  .performance-reporting-overlay {
    width: 100%;
    height: auto;
    min-height: 300px;
    margin-bottom: $--clb-layout-3;

    @include lg-up {
      min-height: 600px;
      align-self: stretch;
      flex-basis: calc(100% - 250px - #{$--clb-layout-3});
      flex-grow: 1;
      margin-bottom: 0;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: $--clb-space-4;
    overflow: hidden;

    &-header--location {
      font-size: $--clb-font-size-sm;
      color: $--clb-color-grey__grey-dark;
      padding: $--clb-space-1;
      margin: 1px $--clb-space-3;
      line-height: $--clb-font-size-base;
      align-self: flex-start;
      white-space: nowrap;
      cursor: pointer;

      &,
      i {
        transition: $--all-transition;
      }

      i {
        display: inline-block;
        color: $--clb-color-grey__grey-dark;
      }

      &:hover {
        color: $--clb-color-grey__grey-darker;

        i {
          color: $--clb-color-primary;
          transform: translateX(-3px);
        }
      }
    }

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}
</style>
