import _debounce from 'lodash/debounce';
import _startCase from 'lodash/startCase';
import { ATTRIBUTE_LABELS } from '@/constants/performance';
import Breakpoints from '@/mixins/Breakpoints';

export default {
  mixins: [Breakpoints([996])],
  props: {
    crowdId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      search: '',
      popover: false,
      showPopover: false,
      debouncedScrollHeight: _debounce(() => this.$refs.scrollbar && this.$refs.scrollbar.update(), 32, { trailing: true })
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organizationId;
    },
    crowds() {
      return this.$store.getters.activeCrowds;
    },
    crowd() {
      return this.$store.getters.getCrowdById(this.crowdId);
    },
    audiences() {
      return (this.crowd || { audiences: [] }).audiences;
    },
    visibleAudiences() {
      return this.audiences.filter((audience) => audience.visible);
    },
    dateFilter() {
      return this.$store.getters.crowdDateFilter;
    },
    leftSidebarVisible() {
      return this.$store.getters.leftSidebarVisible || this.windowBreakpoint > 0;
    },
    popoverDismissed() {
      return this.$store.getters.crowds.length > 3 || (this.$store.getters.crowdPopovers[this.$route.name] || 0);
    }
  },
  watch: {
    '$store.getters.windowHeight'() {
      this.debouncedScrollHeight();
    },
    leftSidebarVisible() {
      if (this.popover) {
        this.popoverTimeout
          ? clearTimeout(this.popoverTimeout)
          : this.showPopover = false;

        this.popoverTimeout = setTimeout(() => {
          this.showPopover = true;
          this.popoverTimeout = undefined;
        }, 200);
      }
    },
    popover: {
      immediate: true,
      handler(popover) {
        if (!popover) this.showPopover = false;
        else setTimeout(() => (this.showPopover = true), 200);
      }
    }
  },
  mounted() {
    this.popover = !this.popoverDismissed;
  },
  methods: {
    handleDateFilter(filter) {
      this.$store.dispatch('setCrowdDateFilter', filter);
    },
    getAttributeLabel(key) {
      return ATTRIBUTE_LABELS[key] || key;
    },
    showAttributeId({ id, name }) {
      return name && id && (!isNaN(id) || (id.includes('-') && !isNaN(id.split('-')[1])));
    },
    getAttributeId({ id }) {
      return `#${id}`;
    },
    getAttributeName({ id, name, type }) {
      if (id === name) return type;
      return name || id;
    },
    dismissPopover() {
      this.popover = false;
      this.$store.dispatch('setCrowdPopoverState', { key: this.$route.name, value: 1 });
    }
  }
};
