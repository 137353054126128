<template>
  <div class="performance-crowd-selection">
    <div class="performance-crowd-selection__heading">
      <app-text type="h3" size="default" color="headings" weight="bold" class="performance-crowd-selection__heading--title">
        All Crowds
      </app-text>
    </div>

    <div class="performance-crowd-selection__controls">
      <div class="performance-crowd-selection__controls--add-item">
        <router-link
          v-keyboard-action
          v-tippy="{ placement: 'right', content: 'Add Crowd', enabled: true, showOnInit: false, arrow: true, followCursor: false }"
          :to="{ name: 'insights-crowd-create' }"
          tag="i"
          class="el-icon-circle-plus"
          tabindex="0" />
      </div>
      <div class="performance-crowd-selection__controls--search">
        <i class="el-icon-search" />
        <input v-model="search" placeholder="Search Crowds">
      </div>
    </div>
    <el-scrollbar ref="scrollbar" class="performance-crowd-selection__cascade" :native="$supportsTouch">
      <template v-if="loading">
        <performance-crowd-selection-list-skeleton v-for="i in 5" :key="`performance-crowd-selection__crowd-item-${i}`" />
      </template>
      <transition-group v-else-if="crowds.length" name="slide-up" tag="div" class="performance-crowd-selection__crowd-list">
        <div v-for="crowd in computedCrowds" :key="`crowd-row-${crowd.id}`" class="performance-crowd-selection__crowd-row" :class="{'performance-crowd-selection__crowd-row--starred' : crowd.favourite}">
          <div
            v-keyboard-action
            class="performance-crowd-selection__crowd-row-wrapper"
            tabindex="0"
            @click="handleCrowdNavigation(crowd.id)">
            <div class="performance-crowd-selection__crowd-info">
              <div class="performance-crowd-selection__crowd-info__constraint">
                <app-text type="span" size="xs" color="primary" class="performance-crowd-selection__crowd-info__constraint-id">
                  {{ crowd.constraint_id }}
                </app-text>
                <app-text type="span" size="xs" color="grey-dark" class="performance-crowd-selection__crowd-info__constraint-name" :title="crowd.constraint_name">
                  {{ crowd.constraint_name }}
                </app-text>
              </div>
              <app-text class="performance-crowd-selection__crowd-name" :title="crowd.name" type="p" weight="medium">
                {{ crowd.name }}
              </app-text>
            </div>
            <div class="performance-crowd-selection__crowd-actions">
              <el-checkbox
                :id="`active-crowd-${crowd.id}`"
                :key="crowd.id"
                :checked="crowd.favourite"
                :label="crowd"
                tabindex="0"
                @change="handleStarCrowd(crowd)"
                @click.native.stop>
                <i :class="`el-icon-star-${crowd.favourite ? 'on' : 'off'}`" />
              </el-checkbox>
              <el-dropdown trigger="click" @click.native.stop>
                <span class="el-dropdown-link">
                  <i :class="crowdBeingDeleted === crowd.id ? 'el-icon-loading' : 'el-icon-more'" />
                </span>
                <el-dropdown-menu slot="dropdown" class="crowd-selection-dd-menu" trigger="click" @click.native.stop>
                  <el-dropdown-item
                    :icon="crowdBeingDeleted === crowd.id ? 'el-icon-loading' : 'el-icon-delete'"
                    :disabled="crowdBeingDeleted === crowd.id"
                    class="crowd-dropdown"
                    @click.native="handleDeleteCrowd(crowd)">
                    Delete
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div key="crowd-row-no-search-results" class="performance-crowd-selection__crowd-row performance-crowd-selection__crowd-row--empty" :style="{ display: !computedCrowds.length? 'flex': 'none' }">
          <div class="performance-crowd-selection__crowd-row-wrapper disabled">
            <app-text type="p" size="xs" color="grey-dark">
              No crowd found matching "{{ search }}"
            </app-text>
          </div>
        </div>
      </transition-group>
      <div v-else class="performance-crowd-selection__crowd-list performance-crowd-selection__crowd-list--empty">
        <app-text type="p" color="info">
          Tap <router-link
            v-keyboard-action
            v-tippy="{ placement: 'top', content: 'Add Crowd', enabled: true, showOnInit: false, arrow: true, followCursor: false }"
            :to="{ name: 'insights-crowd-create' }"
            tag="i"
            class="el-icon-circle-plus" /> or use the button below to create your first crowd
        </app-text>
        <app-button @click="$router.push({ name: 'insights-crowd-create' })">
          Create Crowd
        </app-button>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { Scrollbar } from 'element-ui';
import AppButton from '@/components/AppButton';
import AppText from '@/components/AppText';
import _orderBy from 'lodash/orderBy';
import PerformanceCrowdSelectionListSkeleton from '@/components/skeletons/performance/PerformanceCrowdSelectionListSkeleton';
import PerformanceAnalyticsMixin from '@/mixins/PerformanceAnalyticsMixin';

export default {
  name: 'PerformanceCrowdSelection',
  components: { 'el-scrollbar': Scrollbar, AppButton, AppText, PerformanceCrowdSelectionListSkeleton },
  mixins: [PerformanceAnalyticsMixin],
  data() {
    return {
      crowdBeingDeleted: null
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isLoadingCrowds;
    },
    computedCrowds() {
      return _orderBy(this.crowds, ['favourite', 'updated'], ['desc', 'asc']).filter((crowd) => {
        return !this.search || crowd.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    async handleStarCrowd(crowd) {
      try {
        document.activeElement.blur();
        await this.$api.crowds.toggleCrowdFavourite({
          crowdId: crowd.id,
          params: { favourite: !crowd.favourite },
          storeAction: 'toggleCrowdFavourite'
        });
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      }
    },
    async handleDeleteCrowd(crowd) {
      try {
        this.crowdBeingDeleted = crowd.id;
        await this.$api.crowds.deleteCrowd({
          crowdId: crowd.id,
          storeAction: 'deleteCrowd'
        });
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.crowdBeingDeleted = null;
      }
    },
    handleCrowdNavigation(crowdId) {
      this.$router.push({ name: 'insights-crowd', params: { crowdId } });
      this.$store.dispatch('setLeftSidebarVisibility', false);
    }
  }
};
</script>

<style lang="scss">
.performance-crowd-selection {
  &__heading {
    padding: 0 $--clb-layout-1;

    &--title {
      margin: 0;
      font-size: $--clb-h3__font-size;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    padding: $--clb-space-4;
    border-top: 1px solid $--clb-color-grey__grey-lighter;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    margin-top: $--clb-space-4;
    z-index: 1;
    box-shadow: 0 30px 50px 0 rgba(96, 96, 99, 0.05);

    &--add-item {
      min-width: $--clb-layout-3;
      display: flex;
      align-items: center;

      .el-icon-circle-plus {
        color: $--clb-color-primary;
        cursor: pointer;
      }
    }

    &--search {
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: $--clb-space-4;
      border-left: 1px solid $--clb-color-grey__grey-lighter;

      .el-icon-search {
        font-size: $--clb-font-size-sm;
      }

      input {
        padding-left: $--clb-space-4;
        border: none;
        font-size: $--clb-font-size-sm;

        &::placeholder {
          text-transform: capitalize;
          color: $--color-text-jb-placeholder;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__cascade {
    position: relative;
    width: 100%;
    flex: 1;
  }

  &__crowd-list {
    position: relative;
    width: 100%;

    &--empty {
      text-align: center;
      padding: $--clb-layout-5 $--clb-space-3;

      .el-icon-circle-plus {
        color: $--clb-color-primary;
        cursor: pointer;
      }

      .app-button {
        margin: 1em auto;
      }
    }

    &.disabled {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 105px;
      text-align: center;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $--clb-color-secondary__light;
      font-size: $--clb-font-size-sm;
      line-height: $--clb-font-size-base;
      color: $--clb-border-color-dark;
    }
  }

  &__crowd-row {
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    transition: 200ms ease-in-out;

    &--starred {
      .el-icon-star-on,
      .el-icon-star-on:hover {
        color: $--clb-color-primary !important;
      }
    }

    &--empty .app-text {
      line-height: $--clb-p__line-height * 2 !important;
    }

    &-wrapper {
      padding: $--clb-space-4;
      background-color: $--clb-color-primary__white;
      text-transform: capitalize;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: background-color 200ms ease-in-out;

      @apply tw-bg-white sm:hover:tw-bg-jb-grey-hover;

      &.disabled {
        pointer-events: none;
      }
    }
  }

  &__crowd-info {
    display: flex;
    font-weight: $--clb-font-weight__semi-bold;
    max-width: calc(100% - 47px);
    flex-direction: column;
    align-items: flex-start;
    font-size: $--clb-font-size-sm;

    &__constraint {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-id {
        margin-right: $--clb-space-2;
      }

      &-name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }

    .el-icon-arrow-down {
      margin-left: $--clb-space-2;
      color: $--clb-color-grey__grey-light;
      font-weight: $--clb-font-weight__semi-bold;
      transform-origin: center;
      transition: 200ms ease-in-out;
    }
  }

  &__crowd-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__crowd-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 47px;

    .el-icon-star-on,
    .el-icon-star-off,
    .el-icon-more {
      cursor: pointer;
      color: $--clb-color-grey__grey-light;
      transition: 200ms ease-in-out;

      &:hover {
        color: $--clb-color-grey__grey;
      }
    }

    .el-icon-star-on,
    .el-icon-star-off {
      font-size: $--clb-font-size-base;
      font-weight: $--clb-font-weight__bold;
    }

    .el-checkbox {
      .el-checkbox__input {
        visibility: hidden;
        position: absolute;
        top: -9999px;
        left: -9999px;
        z-index: -1;
      }

      .el-checkbox__label {
        padding: 0 $--clb-space-2;
      }
    }

    .el-icon-more {
      transform: rotate(90deg);
    }

    ul.el-dropdown-menu.el-popper {
      cursor: pointer;
      padding: $--clb-space-2 $--clb-space-3 $--clb-space-3 $--clb-space-3;
      transition: background-color 0.2s ease-in-out;

      &__item {
        :hover {
          background: $--clb-color-secondary__light;
        }
      }
    }
  }

  .el-scrollbar {
    &__wrap {
      overflow-x: hidden;
      margin-bottom: 0 !important;
      overscroll-behavior: contain;
    }

    &__bar.is-horizontal {
      display: none;
    }
  }
}

.el-dropdown-menu__item.crowd-dropdown {
  padding: $--clb-space-2 $--clb-space-3;
  line-height: 1.5;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}
</style>
