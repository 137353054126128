<template>
  <div class="performance-attribute-selection">
    <slot />
    <div class="performance-attribute-selection__heading">
      <app-text type="h3" weight="bold" color="black" class="performance-attribute-selection__heading--title">
        Build Audience
        <el-popover
          v-if="leftSidebarVisible"
          :value="showPopover"
          placement="bottom"
          width="280"
          :offset="-98"
          :trigger="!popoverDismissed ? 'manual' : 'hover'"
          :title="!popoverDismissed ? 'Step 4/4' : ''"
          :content="tooltipInfoText"
          popper-class="dark performance-attribute-selection__popover">
          <i
            slot="reference"
            class="el-icon-info"
            @mouseover="popover = true"
            @click="popover = true" />
          {{ tooltipInfoText }}
          <app-button v-if="!popoverDismissed" size="micro" state="primary" class="performance-attribute-selection__popover-button" @click="dismissPopover">
            Got It!
          </app-button>
        </el-popover>
      </app-text>

      <div v-if="crowd" class="performance-attribute-selection__heading--subtitle">
        <app-text type="p" color="primary" weight="semi-bold" :title="crowd.constraint_id" class="performance-attribute-selection__heading--subtitle-id">
          {{ crowd.constraint_id }}
        </app-text>
        <app-text type="p" size="default" weight="bold" :title="crowd.constraint_name" class="performance-attribute-selection__heading--subtitle-name">
          {{ crowd.constraint_name }}
        </app-text>
      </div>

      <el-input
        ref="input"
        v-model="name"
        type="text"
        maxlength="120"
        class="performance-attribute-selection__heading--name-input"
        :class="{'border-error': submitted && errors.length}"
        placeholder="Name your Audience"
        autocomplete="no"
        @keyup.enter.native="handleSegmentCreation" />

      <transition name="el-zoom-in-top" mode="out-in">
        <div v-if="submitted && errors.length" :key="errors[0]" class="performance-attribute-selection__heading-error">
          {{ errors[0] }}
        </div>
      </transition>

      <el-collapse-transition>
        <transition-group v-if="hasSelectedAttributes" tag="div" name="slide-left" class="performance-attribute-selection__selected-attributes">
          <el-tag
            v-for="attribute in selectedAttributes"
            :key="`${attribute.type}-${attribute.id}`"
            :title="getAttributeLabel(attribute.name || attribute.id)"
            :type="!hasAttributes ? 'danger' : 'primary'"
            closable
            @close="handleAttributeSelection(attribute)">
            {{ getAttributeLabel(attribute.name || attribute.id) }}
          </el-tag>
        </transition-group>
      </el-collapse-transition>

      <div class="performance-attribute-selection__heading--picker">
        <date-picker-filter :value="dateFilter" :class="{'border-error': !hasAttributes && !loading}" @input="handleDateFilter" />
      </div>
    </div>

    <div class="performance-attribute-selection__controls">
      <transition :name="transition || 'slide-right'" mode="out-in">
        <div v-if="activeAttribute" v-keyboard-action class="performance-attribute-selection__controls-back" tabindex="0" @click="goBack">
          <i
            v-tippy="{ placement: 'right', content: 'Back to Attributes', enabled: true, showOnInit: false, arrow: true, followCursor: false }"
            class="el-icon-arrow-left" />
        </div>
      </transition>
      <div
        class="performance-attribute-selection__controls--search"
        :class="{ 'full-width': !activeAttribute }">
        <i class="el-icon-search" />
        <input
          v-model="search"
          :placeholder="[[activeAttribute ? `Search ${getAttributeLabel(activeAttribute, true)}` : 'Search Attributes']]">
      </div>
    </div>

    <el-scrollbar ref="scrollbar" class="performance-attribute-selection__cascade" :native="$supportsTouch">
      <transition :name="transition" mode="out-in">
        <div v-if="loading" v-once class="performance-attribute-selection__cascade-list performance-attribute-selection__cascade-list--loading">
          <performance-crowd-selection-list-skeleton v-for="i in 5" :key="`skeleton-${i}`" />
        </div>
        <div v-else-if="!hasAttributes && !search && !hasSelectedAttributes" class="performance-attribute-selection__cascade-list--error">
          <i class="el-icon-warning" /> <span>No data available.</span> Please select a different date range to continue building an audience.
        </div>
        <div v-else-if="!hasAttributes && !search" class="performance-attribute-selection__cascade-list--error">
          <i class="el-icon-warning" /> <span>No data available.</span> Please remove one or more attributes to continue.
        </div>
        <ul v-else-if="!activeAttribute && filteredAttributes.length" key="main-attributes" class="performance-attribute-selection__cascade-list performance-attribute-selection__main-list">
          <li
            v-for="attributeGroup in filteredAttributes"
            :key="attributeGroup"
            v-keyboard-action
            class="performance-attribute-selection__cascade-list--item"
            tabindex="0"
            @click="handleSelectedAttributeSelection(attributeGroup)">
            <div>
              <app-text weight="medium" color="grey-dark" size="xs" class="performance-attribute-selection__cascade-list--item__percentage">
                {{ getPercentUsers(validUsersForAttribute(attributeGroup), totalUsersForCrowd) }} of users
              </app-text>
              <app-text type="p" weight="medium">
                {{ getAttributeLabel(attributeGroup) }}
              </app-text>
            </div>
            <i class="el-icon-arrow-right" />
          </li>
        </ul>
        <ul
          v-else-if="activeAttribute && filteredActiveAttributes.length"
          key="sub-attributes"
          class="performance-attribute-selection__cascade-list performance-attribute-selection__sub-list">
          <li
            v-for="attribute in filteredActiveAttributes"
            :key="attribute.id"
            v-keyboard-action
            :title="getAttributeLabel(attribute.name || attribute.id)"
            class="performance-attribute-selection__cascade-list--item"
            :class="{ 'active' : isSelectedAttribute(attribute) }"
            tabindex="0"
            @click="handleAttributeSelection(attribute)">
            <div>
              <app-text class="performance-attribute-selection__cascade-list--item__percentage" size="xs">
                <app-text v-if="showAttributeId(attribute)" color="primary">
                  {{ getAttributeId(attribute) }}
                </app-text>
                <app-text v-if="showAttributeId(attribute)" color="grey-light" class="tw-mx-space-1">
                  |
                </app-text>
                <app-text weight="medium" color="grey-dark">
                  {{ getPercentUsers(attribute.users, totalUsersForAttribute(attribute.type)) }} of users
                </app-text>
              </app-text>
              <app-text type="p" weight="medium">
                {{ getAttributeLabel(attribute.name || attribute.id) }}
              </app-text>
            </div>
            <i v-if="isSelectedAttribute(attribute)" class="el-icon-remove" />
            <i v-else class="el-icon-circle-plus" />
          </li>
        </ul>
        <div v-else key="performance-attribute-selection-no-search-results" class="performance-attribute-selection__cascade-list">
          <div class="performance-attribute-selection__cascade-list--item performance-attribute-selection__cascade-list--item--disabled">
            <app-text type="span" size="xs" color="grey-dark">
              No {{ activeAttribute ? getAttributeLabel(activeAttribute) : 'Attributes' }} found matching "{{ search }}"
            </app-text>
          </div>
        </div>
      </transition>
    </el-scrollbar>
    <transition :name="transition" mode="out-in">
      <div v-show="!activeAttribute" class="performance-attribute-selection__cascade-button">
        <div ref="scrollOverlay" class="performance-attribute-selection__cascade-overlay" />
        <app-button :loading="loadingToCreate" :disabled="!hasAttributes || loadingToCreate" @click="handleSegmentCreation">
          Create Audience
        </app-button>
      </div>
    </transition>
  </div>
</template>

<script>
import _isEqual from 'lodash/isEqual';
import { Scrollbar } from 'element-ui';
import { TOOLTIP_AUDIENCE_ATTRIBUTES_HELPER_TEXT, MINIMUM_COLUMN_COUNT_ON_PERFORMANCE_REPORT_TABLE } from '@/constants/performance';
import AppButton from '@/components/AppButton';
import AppText from '@/components/AppText';
import DatePickerFilter from '@/components/filters/DatePickerFilter';
import PerformanceAnalyticsMixin from '@/mixins/PerformanceAnalyticsMixin';
import PerformanceAttributeSelectionMixin from '@/mixins/PerformanceAttributeSelectionMixin';
import PerformanceCrowdSelectionListSkeleton from '@/components/skeletons/performance/PerformanceCrowdSelectionListSkeleton';

export default {
  name: 'PerformanceAttributeSelection',
  components: { 'el-scrollbar': Scrollbar, AppText, AppButton, DatePickerFilter, PerformanceCrowdSelectionListSkeleton },
  mixins: [PerformanceAnalyticsMixin, PerformanceAttributeSelectionMixin],
  data() {
    return {
      name: '',
      tooltipInfoText: TOOLTIP_AUDIENCE_ATTRIBUTES_HELPER_TEXT,
      submitted: false,
      loading: true,
      loadingToCreate: false
    };
  },
  computed: {
    attributes() {
      return this.$store.getters.audienceAttributes;
    },
    errors() {
      const errArr = [];

      if (!this.name) errArr.push('Audience name is required');
      if (!this.hasSelectedAttributes) errArr.push('Audience must contain an attribute');

      if (this.name && this.audiences.some((audience) => audience.name.toLowerCase() === this.name.toLowerCase())) {
        errArr.push('Audience name is already taken');
      }

      const duplicateAudience = this.audiences.find((audience) => _isEqual(this.selectedAttributes, audience.attributes));
      if (duplicateAudience) errArr.push(`Audience is a duplicate of "${duplicateAudience.name}"`);

      return errArr;
    }
  },
  watch: {
    activeAttribute() {
      this.$refs.scrollbar.wrap && (this.$refs.scrollbar.wrap.scrollTop = 0);
    },
    selectedAttributes(attributes) {
      this.fetchAudienceAttributes(attributes);
      const attributesArray = Object.values(attributes);
      if (attributesArray.length === 1 && !this.name) {
        this.name = this.getAttributeLabel(attributesArray[0].name || attributesArray[0].id);
      }
    },
    dateFilter() {
      this.fetchAudienceAttributes(this.selectedAttributes);
    }
  },
  mounted() {
    this.fetchAudienceAttributes();
  },
  destroyed() {
    this.$store.dispatch('clearAudienceAttributes');
  },
  methods: {
    async fetchAudienceAttributes(attributes = {}) {
      try {
        this.loading = true;
        await this.$api.crowds.audienceAttributes({
          crowdId: this.crowdId,
          params: {
            ...this.dateFilter,
            filter: this.formatAttributesForApi(attributes)
          },
          storeAction: 'fetchAudienceAttributes'
        });
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async handleSegmentCreation() {
      this.submitted = true;
      if (this.errors.length) return;

      try {
        this.loadingToCreate = true;
        await this.$api.crowds.addNewAudience({
          crowdId: this.crowdId,
          storeAction: 'addNewAudience',
          params: {
            name: this.name,
            attributes: this.formatAttributesForApi(this.selectedAttributes),
            visible: this.visibleAudiences.length < MINIMUM_COLUMN_COUNT_ON_PERFORMANCE_REPORT_TABLE
          }
        });
        this.$router.replace({ name: 'insights-crowd', params: { crowdId: this.crowdId } });
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.loadingToCreate = false;
      }
    },
    goBack() {
      this.handleSelectedAttributeSelection();
    }
  }
};
</script>

<style lang="scss">
.performance-attribute-selection {
  &__heading {
    padding: 0 $--clb-space-4;

    &--title {
      margin: 0;
      margin-bottom: $--clb-space-2;
      font-size: $--clb-h3__font-size;
      text-transform: capitalize;
      display: flex;

      span {
        cursor: pointer;
        display: flex;
        align-self: center;
        font-size: $--clb-font-size-base;
        color: $--clb-body-font;
        padding-left: $--clb-space-1;
      }
    }

    &--subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-transform: capitalize;

      &-id {
        white-space: nowrap;
      }

      &-name {
        margin-left: $--clb-space-2;
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &--name-input {
      padding: 0;
      margin-top: $--clb-space-3;

      input {
        height: 40px;
        font-size: $--clb-font-size-sm;
        min-height: 40px !important;
      }

      &.border-error .el-input__inner {
        border-color: $--clb-color-danger;
      }
    }

    &-error {
      margin: $--clb-space-2 0 0 $--clb-space-1;
      color: $--clb-color-danger;
      font-size: $--clb-font-size-xs;
      position: relative;
      z-index: 1;
    }

    &--picker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: $--clb-space-3;
      margin-bottom: $--clb-space-4;

      .date-picker-filter__calendar {
        margin-top: $--clb-space-2;
      }

      .el-input__inner {
        min-height: 40px !important;

        .el-range-separator {
          height: 25px;
        }
      }

      .border-error {
        box-shadow: 0 0 0 1px $--clb-color-danger;
        border-radius: $--clb-space-1;

        .date-picker-filter__tabs-list-item.active {
          background: $--clb-color-danger;
        }

        .date-picker-filter__tabs-list-item:hover {
          border-color: $--clb-color-danger;
        }
      }
    }
  }

  &__selected-attributes {
    display: flex;
    flex-wrap: wrap;
    margin: $--clb-space-2 0;
    margin-right: -$--clb-space-2;

    .el-tag {
      position: relative;
      margin: $--clb-space-1 $--clb-space-2;
      margin-left: 0;
      font-weight: $--clb-font-weight__semi-bold;
      max-width: calc(50% - #{$--clb-space-2});
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: $--clb-space-6;
      transition: $--all-transition;

      .el-tag__close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: $--clb-space-1;
        margin: auto;
        font-weight: $--clb-font-weight__semi-bold;
        transition: $--all-transition;

        &:hover {
          color: $--clb-color-primary;
          background-color: rgba($--clb-color-primary, 0.15);
        }
      }
    }
  }

  &__controls {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid $--clb-color-grey__grey-lighter;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    height: 32px + $--clb-space-4;
    z-index: 1;
    box-shadow: 0 30px 50px 0 rgba(96, 96, 99, 0.05);

    &--search {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: calc(100% - #{32px + $--clb-space-4});
      padding-left: $--clb-space-4;
      transition: transform 0.2s ease-out, border 0.2s ease-out;
      transform: translateX(48px);
      border-left: 1px solid $--clb-color-grey__grey-lighter;

      &.full-width {
        max-width: 100%;
        border-left-color: transparent;
        transform: translateX(0);
      }

      .el-icon-search {
        font-size: $--clb-font-size-sm;
      }

      input {
        padding: 0 $--clb-space-4;
        border: none;
        font-size: $--clb-font-size-sm;
        width: 100%;

        &::placeholder {
          text-transform: capitalize;
          color: $--color-text-jb-placeholder;
        }

        &:focus {
          outline: none;
        }
      }
    }

    &-back {
      position: absolute;
      top: 0;
      left: 0;
      width: $--clb-layout-3;
      padding: $--clb-space-2;
      margin: $--clb-space-2;
      cursor: pointer;
    }
  }

  &__cascade {
    position: relative;
    width: 100%;
    flex: 1;

    &-list {
      width: 100%;

      &--error {
        padding: $--clb-layout-5 $--clb-space-4;
        font-size: $--clb-font-size-sm;
        text-align: center;

        span {
          font-size: $--clb-font-size-sm;
          font-weight: $--clb-font-weight__bold;
        }
      }
    }

    &-list--item {
      padding: $--clb-space-4;
      border-bottom: 1px solid $--clb-color-grey__grey-lighter;
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 200ms ease-in-out;

      @apply tw-bg-white sm:hover:tw-bg-jb-grey-hover;

      &__percentage {
        letter-spacing: 0.5px;
        text-transform: lowercase;
      }

      .el-icon-circle-plus {
        color: $--clb-color-primary;
      }

      .el-icon-arrow-right {
        color: $--clb-color-grey__grey-dark;
      }

      &--disabled {
        pointer-events: none;
      }
    }

    &-overlay {
      position: absolute;
      bottom: 80px;
      left: 0;
      right: 0;
      padding-top: $--clb-layout-6;
      background: linear-gradient(to top, white, rgba(white, 0));
      pointer-events: none;
      transform-origin: bottom;
    }
  }

  &__cascade-button {
    width: 100%;
    padding: $--clb-layout-2 $--clb-space-4 $--clb-space-4 $--clb-space-4;
    position: relative;
    overflow: visible;

    .app-button {
      width: 100%;
    }
  }

  .el-scrollbar {
    &__wrap {
      overflow-x: hidden;
      margin-bottom: 0 !important;
      overscroll-behavior: contain;
    }

    &__bar.is-horizontal {
      display: none;
    }
  }

  &__popover {
    &-button {
      margin: $--clb-space-4 0 0 auto;
    }
  }
}
</style>
