import _debounce from 'lodash/debounce';
import _uniqBy from 'lodash/uniqBy';

export default {
  data() {
    return {
      transitioning: false,
      activeAttribute: undefined,
      selectedAttributes: {},
      debouncedScrollListener: _debounce(this.handleScrollOverlayScale, 32, { trailing: true })
    };
  },
  computed: {
    transition() {
      if (!this.transitioning) return '';
      return !this.activeAttribute ? 'slide-right' : 'slide-left';
    },
    hasAttributes() {
      return (!this.activeAttribute ? Object.keys(this.attributes).length : this.activeAttributes.length) > 0;
    },
    filteredAttributes() {
      return Object.keys(this.attributes).filter((attribute) => {
        return !this.search || attribute.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    activeAttributes() {
      const selectedAttributesForType = Object.values(this.selectedAttributes).filter(({ type }) => type === this.activeAttribute);
      return _uniqBy([...(this.attributes[this.activeAttribute] || []), ...selectedAttributesForType], 'id');
    },
    filteredActiveAttributes() {
      return this.activeAttributes.filter((attribute) => {
        const search = this.search.toLowerCase();
        return attribute.id !== null && (
          !search ||
          // attribute has id, try to match that
          attribute.id.toLowerCase().includes(search) ||
          // attribute has a name, loose match
          (attribute.name && attribute.name.toLowerCase().includes(search))
        );
      }).slice(0, 100);
    },
    hasSelectedAttributes() {
      return Object.values(this.selectedAttributes).length > 0;
    },
    totalUsersForAttributes() {
      const totals = {};
      Object.entries(this.attributes).forEach(([attribute, rows]) => {
        totals[attribute] = rows.reduce(({ total, valid }, { id, users }) => {
          return { total: total + users, valid: valid + (id !== null ? users : 0) };
        }, { total: 0, valid: 0 });
      });
      return totals;
    },
    totalUsersForCrowd() {
      return Math.min(...Object.values(this.totalUsersForAttributes).map(totals => totals.total));
    }
  },
  watch: {
    activeAttribute() {
      clearTimeout(this.transitioning);
      this.transitioning = setTimeout(() => (this.transitioning = false), 300);
    }
  },
  mounted() {
    // watch for scrollbar updated lifecycle hook
    this.$refs.scrollbar.$on('hook:updated', this.debouncedScrollListener);
  },
  methods: {
    totalUsersForAttribute(attribute) {
      return (this.totalUsersForAttributes[attribute] || { total: 0 }).total;
    },
    validUsersForAttribute(attribute) {
      return (this.totalUsersForAttributes[attribute] || { valid: 0 }).valid;
    },
    getPercentUsers(numerator, denominator) {
      const percent = denominator > 0 ? Math.floor(numerator / denominator * 100) : 0;
      return percent < 1 ? '<1%' : Math.min(percent, 100) + '%';
    },
    handleSelectedAttributeSelection(name) {
      this.activeAttribute = name;
      this.search = '';
    },
    isSelectedAttribute({ id, type }) {
      return this.selectedAttributes[`${type}-${id}`];
    },
    handleAttributeSelection(attribute) {
      if (this.isSelectedAttribute(attribute)) {
        this.$delete(this.selectedAttributes, `${attribute.type}-${attribute.id}`);
      } else {
        this.$set(this.selectedAttributes, `${attribute.type}-${attribute.id}`, attribute);
        this.handleSelectedAttributeSelection();
      }
    },
    formatAttributesForApi(attributes) {
      const formatted = {};

      // Filter by attributes
      Object.values(attributes).forEach(({ type, users, ...rest }) => {
        formatted[type] = formatted[type] || [];
        formatted[type].push(rest);
      });

      return formatted;
    },
    handleScrollOverlayScale() {
      if (this.$refs.scrollbar && this.$refs.scrollOverlay) {
        const wrap = this.$refs.scrollbar.wrap;
        const height = 160;
        const pos = Math.min(wrap.scrollHeight - (wrap.scrollTop + wrap.clientHeight), height);
        const scale = height ? parseFloat((pos / height).toFixed(2)) : 0;
        this.$refs.scrollOverlay.style.transform = `scaleY(${scale})`;
      }
    }
  }
};
