<template>
  <div class="performance-crowd-selection-skeleton">
    <skeleton-box v-once height="12px" :width="(30 + Math.floor(Math.random() * 5)) + '%'" />
    <skeleton-box v-once height="16px" :width="(65 + Math.floor(Math.random() * 15)) + '%'" />
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'PerformanceCrowdSelectionListSkeleton',
  components: { SkeletonBox }
};
</script>

<style lang="scss">
.performance-crowd-selection-skeleton {
  pointer-events: none;
  height: 71px;
  padding: $--clb-space-4 $--clb-space-4 $--clb-space-4 $--clb-space-4;
  border-bottom: 1px solid $--clb-border-color-lighter;
  font-size: $--clb-font-size-sm !important;

  .skeleton-box {
    margin: 0;
    margin-bottom: $--clb-space-1;
  }
}
</style>
