import { render, staticRenderFns } from "./AppGradientCell.vue?vue&type=template&id=6ff0980b&scoped=true&"
import script from "./AppGradientCell.vue?vue&type=script&lang=js&"
export * from "./AppGradientCell.vue?vue&type=script&lang=js&"
import style0 from "./AppGradientCell.vue?vue&type=style&index=0&id=6ff0980b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff0980b",
  null
  
)

export default component.exports