<template>
  <div class="performance-crowd-constraint-selection">
    <slot />
    <div class="performance-crowd-constraint-selection__heading">
      <app-text type="h3" weight="bold" color="black" class="performance-crowd-constraint-selection__heading--title">
        Build Crowd
        <el-popover
          v-if="leftSidebarVisible"
          v-model="showPopover"
          placement="bottom"
          width="280"
          :offset="-68"
          :trigger="!popoverDismissed ? 'manual' : 'hover'"
          :title="!popoverDismissed ? 'Step 1/4' : ''"
          :content="tooltipInfoText"
          popper-class="dark performance-crowd-constraint-selection__popover">
          <i
            slot="reference"
            class="el-icon-info"
            @mouseover="popover = true"
            @click="popover = true" />
          {{ tooltipInfoText }}
          <app-button v-if="!popoverDismissed" size="micro" state="primary" class="performance-crowd-constraint-selection__popover-button" @click="dismissPopover">
            Got It!
          </app-button>
        </el-popover>
      </app-text>
      <div class="performance-crowd-constraint-selection__heading--picker">
        <date-picker-filter :value="dateFilter" :class="{'border-error': !loading && !hasAttributes }" @input="handleDateFilter" />
      </div>
    </div>
    <div class="performance-crowd-constraint-selection__controls">
      <transition :name="transition || 'slide-right'" mode="out-in">
        <div v-if="activeAttribute" v-keyboard-action class="performance-crowd-constraint-selection__controls-back" tabindex="0" @click="goBack">
          <i
            v-tippy="{ placement: 'right', content: 'Back to Attributes', enabled: true, showOnInit: false, arrow: true, followCursor: false }"
            class="el-icon-arrow-left" />
        </div>
      </transition>
      <div
        class="performance-crowd-constraint-selection__controls--search"
        :class="{ 'full-width': !activeAttribute }">
        <i class="el-icon-search" />
        <input v-model="search" :placeholder="searchPlaceholder">
      </div>
    </div>
    <el-scrollbar ref="scrollbar" class="performance-crowd-constraint-selection__cascade" :native="$supportsTouch">
      <transition :name="transition" mode="out-in">
        <div v-if="loading" v-once class="performance-crowd-constraint-selection__cascade-list performance-crowd-constraint-selection__cascade-list--loading">
          <performance-crowd-selection-list-skeleton v-for="i in 5" :key="`skeleton-${i}`" />
        </div>
        <div v-else-if="!hasAttributes" class="performance-crowd-constraint-selection__cascade-list--error">
          <i class="el-icon-warning" /> <span>No data available.</span> Please select a different date range to continue building a crowd.
        </div>
        <ul v-else :key="activeAttribute" class="performance-crowd-constraint-selection__cascade-list performance-crowd-constraint-selection__main-list">
          <li
            v-for="constraint in filteredConstraints"
            :key="constraint.id"
            v-keyboard-action
            class="performance-crowd-constraint-selection__cascade-list--item"
            tabindex="0"
            @click="handleSelectConstraint(constraint)">
            <div>
              <app-text class="performance-crowd-constraint-selection__cascade-list--item__percentage" size="xs">
                <app-text v-if="showAttributeId(constraint)" color="primary">
                  {{ getAttributeId(constraint) }}
                </app-text>
                <app-text v-if="showAttributeId(constraint)" color="grey-light" class="tw-mx-space-1">
                  |
                </app-text>
                <app-text weight="medium" color="grey-dark">
                  {{ activeAttribute ? getPercentUsers(constraint.users, totalUsersForAttribute(constraint.type)) : getPercentUsers(validUsersForAttribute(constraint.type), totalUsersForCrowd) }} of users
                </app-text>
              </app-text>
              <app-text type="p" weight="medium">
                {{ getAttributeLabel(constraint.name || constraint.id) }}
              </app-text>
            </div>
            <i class="el-icon-arrow-right" />
          </li>
          <li key="performance-crowd-constraint-no-search-results" class="performance-crowd-constraint-selection__cascade-list--item performance-crowd-constraint-selection__cascade-list--item--empty" :style="{ display: !filteredConstraints.length? 'flex': 'none' }">
            <app-text type="span" size="md" color="grey-dark">
              No {{ constraintLabel }} found
            </app-text>
          </li>
        </ul>
      </transition>
    </el-scrollbar>
  </div>
</template>

<script>
import { Scrollbar } from 'element-ui';
import { TOOLTIP_CROWD_HELPER_TEXT, ATTRIBUTE_LABELS } from '@/constants/performance';
import AppButton from '@/components/AppButton';
import AppText from '@/components/AppText';
import DatePickerFilter from '@/components/filters/DatePickerFilter';
import PerformanceAnalyticsMixin from '@/mixins/PerformanceAnalyticsMixin';
import PerformanceAttributeSelectionMixin from '@/mixins/PerformanceAttributeSelectionMixin';
import PerformanceCrowdSelectionListSkeleton from '@/components/skeletons/performance/PerformanceCrowdSelectionListSkeleton';

export default {
  name: 'PerformanceCrowdConstraintSelection',
  components: { 'el-scrollbar': Scrollbar, AppButton, AppText, DatePickerFilter, PerformanceCrowdSelectionListSkeleton },
  mixins: [PerformanceAnalyticsMixin, PerformanceAttributeSelectionMixin],
  data() {
    return {
      loading: false,
      tooltipInfoText: TOOLTIP_CROWD_HELPER_TEXT
    };
  },
  computed: {
    attributes() {
      return this.$store.getters.crowdConstraints;
    },
    constraintLabel() {
      return this.activeAttribute ? this.getAttributeLabel(this.activeAttribute) : 'Constraints';
    },
    searchPlaceholder() {
      return `Search ${this.constraintLabel}`;
    },
    filteredConstraints() {
      return this.activeAttribute ? this.filteredActiveAttributes : this.filteredAttributes.map((id) => ({ id, name: ATTRIBUTE_LABELS[id], type: id }));
    }
  },
  watch: {
    activeAttribute() {
      this.$refs.scrollbar.wrap && (this.$refs.scrollbar.wrap.scrollTop = 0);
    },
    dateFilter() {
      this.fetchCrowdConstraints();
    }
  },
  created() {
    this.fetchCrowdConstraints();
  },
  methods: {
    async fetchCrowdConstraints() {
      try {
        this.loading = true;
        await this.$api.crowds.crowdConstraints({ params: { ...this.dateFilter }, storeAction: 'fetchCrowdConstraints' });
      } catch (error) {
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    handleSelectConstraint({ id, name }) {
      if (!this.activeAttribute) {
        this.handleSelectedAttributeSelection(id);
      } else {
        this.$store.dispatch('setCreatedCrowd', { constraint_type: this.activeAttribute, constraint_id: id, constraint_name: name });
        this.$router.push({ name: 'insights-crowd-create-attributes' });
      }
    },
    goBack() {
      this.handleSelectedAttributeSelection();
    }
  }
};
</script>

<style lang="scss">
.performance-crowd-constraint-selection {
  &__heading {
    padding: 0 $--clb-space-4;

    &--title {
      margin: 0;
      margin-bottom: $--clb-space-2;
      font-size: $--clb-h3__font-size;
      text-transform: capitalize;
      display: flex;

      span {
        cursor: pointer;
        display: flex;
        align-self: center;
        font-size: $--clb-font-size-base;
        color: $--clb-body-font;
        padding-left: $--clb-space-1;
      }
    }

    &--picker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: $--clb-space-3;
      margin-bottom: $--clb-space-4;

      .date-picker-filter__calendar {
        margin-top: $--clb-space-2;
      }

      .el-input__inner {
        min-height: 40px !important;

        .el-range-separator {
          height: 25px;
        }
      }

      .border-error {
        box-shadow: 0 0 0 1px $--clb-color-danger;
        border-radius: $--clb-space-1;

        .date-picker-filter__tabs-list-item.active {
          background: $--clb-color-danger;
        }

        .date-picker-filter__tabs-list-item:hover {
          border-color: $--clb-color-danger;
        }
      }
    }
  }

  .el-input {
    padding: 0 $--clb-space-4;

    input {
      height: 40px;
      font-size: $--clb-font-size-sm;
      min-height: 40px !important;
    }
  }

  &__controls {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid $--clb-color-grey__grey-lighter;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    height: 32px + $--clb-space-4;
    z-index: 1;
    box-shadow: 0 30px 50px 0 rgba(96, 96, 99, 0.05);

    &--search {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: calc(100% - #{32px + $--clb-space-4});
      padding-left: $--clb-space-4;
      transition: transform 0.2s ease-out, border 0.2s ease-out;
      transform: translateX(48px);
      border-left: 1px solid $--clb-color-grey__grey-lighter;

      &.full-width {
        max-width: 100%;
        border-left-color: transparent;
        transform: translateX(0);
      }

      .el-icon-search {
        font-size: $--clb-font-size-sm;
      }

      input {
        padding: 0 $--clb-space-4;
        border: none;
        font-size: $--clb-font-size-sm;
        width: 100%;

        &::placeholder {
          text-transform: capitalize;
          color: $--color-text-jb-placeholder;
        }

        &:focus {
          outline: none;
        }
      }
    }

    &-back {
      position: absolute;
      top: 0;
      left: 0;
      width: $--clb-layout-3;
      padding: $--clb-space-2;
      margin: $--clb-space-2;
      cursor: pointer;
    }
  }

  &__cascade {
    position: relative;
    width: 100%;
    flex: 1;

    &-list {
      width: 100%;

      &--error {
        padding: $--clb-layout-5 $--clb-space-4;
        font-size: $--clb-font-size-sm;
        text-align: center;

        span {
          font-size: $--clb-font-size-sm;
          font-weight: $--clb-font-weight__bold;
        }
      }
    }

    &-list--item {
      padding: $--clb-space-4;
      border-bottom: 1px solid $--clb-color-grey__grey-lighter;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: background-color 200ms ease-in-out;

      @apply tw-bg-white sm:hover:tw-bg-jb-grey-hover;

      &__percentage {
        letter-spacing: 0.5px;
        text-transform: lowercase;
      }

      &__constraint-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .el-icon-circle-plus {
        color: $--clb-color-primary;
      }

      .el-icon-arrow-right {
        color: $--clb-color-grey__grey-dark;
      }

      &--empty {
        pointer-events: none;
      }
    }
  }

  .el-scrollbar {
    &__wrap {
      overflow-x: hidden;
      margin-bottom: 0 !important;
      overscroll-behavior: contain;
    }

    &__bar.is-horizontal {
      display: none;
    }
  }

  &__popover {
    &-button {
      margin: $--clb-space-4 0 0 auto;
    }
  }
}
</style>
