<template>
  <div class="performance-reporting-instructions">
    <div class="performance-reporting-instructions__media-object">
      <div class="performance-reporting-instructions__media-object--title">
        <crowd-icon class="crowd-icon" />
        <app-text type="h2" weight="normal" color="black">
          Crowds
        </app-text>
      </div>
      <div class="performance-reporting-instructions__media-object--copy">
        <app-text type="p">
          Crowds are the combination of several Audiences and an applied filter. Click the crowd symbol to switch or create a crowd. Favourite a crowd by clicking its star for quick access later.
        </app-text>
      </div>
    </div>

    <div class="performance-reporting-instructions__media-object">
      <div class="performance-reporting-instructions__media-object--title">
        <i class="el-icon-aim" />
        <app-text type="h2" weight="normal" color="black">
          Audiences
        </app-text>
      </div>
      <div class="performance-reporting-instructions__media-object--copy">
        <app-text type="p">
          Audiences allow you to partition data based on one or multiple attributes in order to target performant segments. View your Audiences in a Crowd or create a new one to compare. We'll save all your work so it's ready for your next analysis session.
        </app-text>
      </div>
    </div>
  </div>
</template>

<script>
import CrowdIcon from '@/assets/svg/crowd-icon.svg';
import AppText from '@/components/AppText';

export default {
  name: 'PerformanceReportingInstructions',
  components: { CrowdIcon, AppText }
};
</script>

<style lang="scss">
.performance-reporting-instructions {
  background: $--clb-color-primary__white;
  border-radius: 4px;
  width: 100%;

  &__media-object {
    &--title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    h2 {
      margin-left: 11px;
    }

    i {
      margin-bottom: 3px;
      font-size: 19px;
    }
  }

  &__media-object .crowd-icon {
    width: $--clb-space-5;
    height: $--clb-layout-1;
  }

  @include lg-up {
    > div:nth-child(1) > div.performance-reporting-instructions__media-object--title > h2 {
      margin-top: 0 !important;
    }

    > div:nth-child(1) > div.performance-reporting-instructions__media-object--title > .crowd-icon {
      position: relative;
      top: -10px;
    }
  }
}
</style>
