<template>
  <div :class="['app-gradient-cell', { inverted: (numberValue / max >= 0.80), max: isMax }]" :style="{ background: colorCode }">
    <slot />
  </div>
</template>

<script>
import { GradientHelper } from '@/helpers/GradientHelper';

export default {
  name: 'AppGradientCell',
  props: {
    max: {
      type: Number,
      default: 100
    },
    value: {
      type: [Number, String],
      default: 0
    },
    easing: {
      type: Function,
      default(t, c, d) {
        t /= d;
        return c * t * t;
      }
    }
  },
  data() {
    return {
      gradientHelper: new GradientHelper({
        min: 0,
        max: this.max
      })
    };
  },
  computed: {
    numberValue() {
      const value = (typeof this.value === 'string') ? parseFloat(this.value.replace(/[^\d.]/g, '')) : this.value;
      return isNaN(value) ? 0 : value;
    },
    isMax() {
      return this.max && this.numberValue >= this.max;
    },
    colorCode() {
      return this.gradientHelper.getColorCode(Math.min(this.easing(this.numberValue, this.numberValue, this.max), this.max));
    }
  }
};
</script>

<style lang="scss" scoped>
.app-gradient-cell {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: $--clb-color-grey__grey-dark;
  font-size: $--clb-font-size-sm;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  &.max {
    font-weight: 700;
  }

  &.inverted {
    color: white;
  }
}

</style>
