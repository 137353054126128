<template>
  <div class="performance-reporting-overlay">
    <div class="performance-reporting-overlay__content">
      <div class="performance-reporting-overlay__content-icon">
        <crowd-illustration class="crowd-icon" />
      </div>
      <app-heading level="h4" weight="semi-bold" class="performance-reporting-overlay__content-title tw-text-jb-ink tw-mt-layout-2 tw-mb-layout-1">
        Build Crowd
      </app-heading>
      <div class="performance-reporting-overlay__content-dialog tw-text-center">
        <app-text type="p" size="sm" class="tw-text-jb-grey-700 tw-w-72 tw-max-w-full tw-mx-auto tw-break-normal" :style="{ hyphens: 'manual' }">
          Open the tab on the right to view crowd insights for your campaign of interest
        </app-text>
        <app-button state="indigo" class="tw-mt-layout-1 tw-mx-auto" @click="handleStartButtonClick">
          Start Insights
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import CrowdIllustration from '@/assets/svg/crowd-illustration.svg';
import AppText from '@/components/AppText';
import AppHeading from '@/components/AppHeading';
import AppButton from '@/components/AppButton';

export default {
  name: 'PerformanceReportingOverlay',
  components: { CrowdIllustration, AppText, AppHeading, AppButton },
  methods: {
    handleStartButtonClick() {
      this.$store.dispatch('setRightDrawerVisibility', true);
      this.$router.push({ name: 'insights-crowd-create', params: this.$route.params });
    }
  }
};
</script>

<style lang="scss">
.performance-reporting-overlay {
  background: $--clb-color-primary__white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $--clb-color-grey__grey-lighter;
  width: 100%;
  height: 400px;

  @apply tw-py-layout-4 tw-px-layout-1;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-icon {
      width: 150px;
    }
  }
}
</style>
