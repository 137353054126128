export class GradientHelper {
  constructor(options = {}) {
    options = Object.assign({
      min: 0,
      max: 10000,
      colorBucket: [
        '#baa4f7',
        '#c4aef7',
        '#cdb8f7',
        '#d6c3f8',
        '#decdf8',
        '#e6d8f9',
        '#ede3f9',
        '#f4eefa',
        '#faf9fb'
      ],
      voidColor: '#fafbfc'
    }, options);
    this.colorBucket = options.colorBucket;
    this.voidColor = options.voidColor;
    this.min = options.min === options.max ? options.max - 1 : options.min;
    this.max = options.max;
    this.stepSize = (this.max - this.min) / this.colorBucket.length;
  }

  // Get the final color code
  getColorCode(value) {
    return value === undefined || value === this.min
      ? this.voidColor
      : this.colorBucket[this.colorBucket.length - 1 - Math.min(Math.floor((value - this.min) / this.stepSize), this.colorBucket.length - 1)];
  }
}
